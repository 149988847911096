mat-drawer {
  padding: 10px;
  width: 300px;
  overflow: hidden;
  overflow-y: none;
  overflow-x: none;
}

.mat-drawer-inner-container {
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

main {
  min-height: 800px;
  padding: 25px;
  padding-top: 80px;
}

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}

p {
  color: #3c4858;
  font-weight: 300;
}

th, td {
  text-align: center;
}

table {
  margin-top: 50px;
  *padding: 25px;
  background-color: #ffffff;
  box-shadow: 3px 2px 2px #cccccc;
  border-bottom: 0;
}

i.material-icons {
  font-size: 14px;
}